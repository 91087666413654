import React, { useEffect } from "react";
import Scrollbar from "../common/scrollbar";
import { useCart } from "@/contexts/cart/cart.context";
import { m } from "@/lib/framer-motion";
import { fadeInOut } from "@/utils/motion/fade-in-out";
import { useUI } from "@/contexts/ui.context";
import usePrice from "@/framework/product/use-price";
import { IoClose } from "react-icons/io5";
import CartItem from "./cart-item";
import EmptyCart from "./empty-cart";
import Link from "next/link";
import cn from "classnames";
import { event } from "@/lib/ga";
import * as fbq from "../../lib/fpixel";

export default function Cart() {
  const { closeC, displayCart } = useUI();
  const { items, total, isEmpty } = useCart();
  const { price: cartTotal } = usePrice({
    amount: total,
    currencyCode: "INR",
  });

  useEffect(() => {
    if (displayCart) {
      const productList = items?.map((singleItem) => ({
        item_id: singleItem?.id,
        item_name: singleItem?.name,
        item_category: singleItem?.productType,
        item_variant: singleItem?.attributes?.size,
        price: singleItem?.itemTotal,
        quantity: singleItem?.quantity,
      }));
      event("view_cart", {
        currency: "INR",
        value: total,
        items: productList,
      });
    }
  }, [displayCart, items, total]);
  return (
    <div className="flex flex-col justify-between w-full h-full">
      <div className="w-full flex justify-between items-center relative pl-5 md:pl-7 py-0.5 border-b border-gray-100">
        <h2 className="m-0 text-xl font-bold md:text-2xl text-heading">
          {/* @ts-ignore */}
          Shopping Cart
        </h2>
        <button
          className="flex items-center justify-center px-4 py-6 text-2xl text-gray-500 transition-opacity md:px-6 lg:py-8 focus:outline-none hover:opacity-60"
          onClick={closeC}
          aria-label="close"
        >
          <IoClose className="text-black mt-1 md:mt-0.5" />
        </button>
      </div>
      {!isEmpty ? (
        <Scrollbar className="flex-grow w-full cart-scrollbar">
          <div className="w-full px-5 md:px-7">
            {items?.map((item, key) => (
              <CartItem item={item} key={"cartItem" + key} />
            ))}
          </div>
        </Scrollbar>
      ) : (
        <m.div
          layout
          initial="from"
          animate="to"
          exit="from"
          variants={fadeInOut(0.25)}
          className="flex flex-col items-center justify-center px-5 pt-8 pb-5 md:px-7"
        >
          <EmptyCart />
          <h3 className="pt-8 text-lg font-bold text-heading">
            {/* @ts-ignore */}
            Empty Cart
          </h3>
        </m.div>
      )}

      <div
        className="flex flex-col px-5 pt-2 pb-5 md:px-7 md:pb-7"
        onClick={closeC}
      >
        <Link
          href={isEmpty === false ? "/checkout" : "/"}
          className={cn(
            "w-full px-5 py-3 md:py-4 flex items-center justify-center rounded-md text-sm sm:text-base text-white focus:outline-none transition duration-300 ",
            isEmpty
              ? "cursor-not-allowed bg-gray-400 hover:bg-gray-400"
              : "bg-heading hover:bg-gray-600"
          )}
        >
          <div
            className="flex w-full items-center"
            onClick={() => {
              if (!isEmpty) {
                const productList = items.map((singleItem) => ({
                  item_id: singleItem?.id,
                  item_name: singleItem?.name,
                  item_category: singleItem?.productType,
                  item_variant: singleItem?.attributes?.size,
                  price: singleItem?.itemTotal,
                  quantity: singleItem?.quantity,
                }));

                event("begin_checkout", {
                  currency: "INR",
                  value: total,
                  items: productList,
                });
                fbq.event("InitiateCheckout", {
                  content_ids: items?.map((singleItem) => singleItem?.id),
                  contents: items?.map((singleItem) => ({
                    id: singleItem?.id,
                    name: singleItem?.name,
                    quantity: singleItem?.quantity,
                  })),
                  num_items: items?.reduce(
                    (acc, singleItem) => acc + (singleItem?.quantity || 0),
                    0
                  ),
                  currency: "INR",
                  value: total,
                });
              }
            }}
          >
            <span className="w-full pr-5 -mt-0.5 py-0.5">
              {/* @ts-ignore */}
              Proceed To Checkout
            </span>
            <span className="ml-auto flex-shrink-0 -mt-0.5 py-0.5 flex">
              <span className="border-l border-white pr-5 py-0.5" />
              {cartTotal}
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
}
