import cn from "classnames";
import React, { useEffect, useRef } from "react";
import { useInfiniteHits } from "react-instantsearch";
export function InfiniteHits({
  hitComponent: HitComponent,
  variant = "page",
  ...props
}) {
  const { items, isLastPage, showMore } = useInfiniteHits(props);
  const sentinelRef = useRef(null);

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  return (
    <div className="ais-InfiniteHits w-full">
      <div
        className={cn("ais-InfiniteHits-list grid auto-rows-auto w-full", {
          "lg:grid-cols-4 md:grid-cols-3 grid-cols-2": variant === "grid",
          "grid-cols-1": variant === "page",
        })}
      >
        {items.map((hit, key) => (
          <div key={"hit" + key} className="ais-InfiniteHits-item">
            <HitComponent hit={hit} />
          </div>
        ))}
        <div
          className="ais-InfiniteHits-sentinel"
          ref={sentinelRef}
          aria-hidden="true"
        />
      </div>
    </div>
  );
}
