import SearchIcon from "../icons/search-icon";
import React, { useEffect } from "react";
import { useUI } from "@/contexts/ui.context";
import { IoCloseOutline } from "react-icons/io5";
import { useSearchBox } from "react-instantsearch";
import { useRouter } from "next/router";
import { event } from "@/lib/ga";
import { useForm } from "react-hook-form";

export default function SearchBox(props) {
  const { closeSearch } = useUI();
  const { query, refine } = useSearchBox(props);
  const router = useRouter();
  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    if (router?.query?.query) {
      refine(router?.query?.query);
      setValue("search", router.query.query); // Update the form value
    }
  }, [router?.query?.query, refine, setValue]);

  function setQuery(newQuery) {
    setValue("search", newQuery); // Update the form value
    if (newQuery.length > 4) {
      const payload = {
        search_term: newQuery,
      };
      event("search", payload);
    }
    refine(newQuery);
    if (router?.pathname === "/search") {
      if (newQuery) {
        router.replace(
          {
            pathname: router.pathname,
            query: { query: newQuery },
          },
          undefined,
          { shallow: true }
        );
      } else {
        router.replace(
          {
            pathname: router.pathname,
            query: {}, // Clear the query parameter
          },
          undefined,
          { shallow: true }
        );
      }
    }
  }

  async function onSubmit(data) {
    closeSearch();
    router.push(`/search?query=${encodeURIComponent(data.search)}`); // Redirect to search page with query
  }

  const handleClose = () => {
    setValue("search", ""); // Clear the input value
    closeSearch(); // Close the search
    setQuery("");
    if (router?.pathname === "/search") {
      router.replace(router.pathname, undefined, { shallow: true });
    } // Clear the query parameter
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      className="relative pr-12 md:pr-14 bg-white overflow-hidden rounded-md w-full"
    >
      <label htmlFor="search" className="flex items-center py-0.5">
        <span className="flex items-center justify-center flex-shrink-0 w-12 h-full cursor-pointer md:w-14 focus:outline-none">
          <SearchIcon color="text-heading" className="w-4 h-4" />
        </span>
        <input
          id="search"
          className="w-full h-12 text-sm placeholder-gray-400 outline-none text-heading lg:h-14 lg:text-base"
          aria-label="Search"
          autoComplete="off"
          {...register("search", {
            required: "Please enter a search term.",
            onChange: (event) => setQuery(event.currentTarget.value),
          })}
        />
      </label>
      <button
        type="button"
        className="absolute top-0 flex items-center justify-center w-12 h-full text-2xl text-gray-400 transition duration-200 ease-in-out outline-none md:text-3xl right-0 md:w-14 hover:text-heading focus:outline-none"
        onClick={handleClose} // Call handleClose on click
      >
        <IoCloseOutline className="w-6 h-6" />
      </button>
    </form>
  );
}
